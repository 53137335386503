<template>
  <div>
    <!-- 面包屑导航区域 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>商业秘密评估</el-breadcrumb-item>
      <el-breadcrumb-item>诊断页面图片</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 卡片视图区 -->
    <el-card>
      <!-- 搜索与添加区域 -->
      <!-- :gutter表示间距:span表示占据比例clear在clearable点击时触发slot="append"输入框带后置元素 -->
      <!-- 表格 -->
      <!-- stripe设置斑马纹表格border设置竖边框 -->
      <el-table :data="routerList" border stripe>
        <el-table-column type="index"></el-table-column>
        <el-table-column prop="img_src" label="略缩图">
          <template slot-scope="scope">
            <img
              :src="$store.state.publicUrl + scope.row.img_src"
              style="width:100px"
            />
          </template>
        </el-table-column>
        <el-table-column prop="title" label="标题"></el-table-column>
        <el-table-column label="操作" width="120px">
          <template slot-scope="scope">
            <el-tooltip
              effect="dark"
              content="编辑"
              placement="top"
              :enterable="false"
            >
              <el-button
                type="primary"
                icon="el-icon-edit"
                size="mini"
                @click="
                  showEditDialog(scope.row)
                  dialogTitle = '编辑'
                "
              ></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <!-- 对话框 -->
    <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogVisible"
      width="500px"
      @close="dialogClosed"
    >
      <el-form
        :model="form"
        ref="formRef"
        :rules="formRules"
        label-width="80px"
        size="mini"
      >
        <el-form-item label="标题" prop="title">
          <el-input v-model="form.title"></el-input>
        </el-form-item>
        <el-form-item label="图片" prop="img_src">
          <el-input v-model="form.img_src" v-show="false"></el-input>
          <el-upload
            class="upload-demo"
            :action="
              $store.state.publicUrl + 'admin/diagnosisImg/uploadFileImg.htm'
            "
            :headers="uploadHeaders"
            accept=".jpeg,.jpg,.gif,.png"
            multiple
            :on-change="handleChange"
            :on-success="handleSuccess"
            :file-list="fileList"
          >
            <el-button size="small" type="primary">点击上传</el-button>
          </el-upload>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addList" v-if="dialogTitle === '添加'"
          >确 定</el-button
        >
        <el-button type="primary" @click="editList" v-else>确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    const validateLogo = (rule, value, callback) => {
      if (!this.form.img_src) {
        callback(new Error('请上传图片'))
      } else {
        callback()
      }
    }
    return {
      // 获取用户列表的参数对象
      queryInfo: {
        keywords: '',
        information_type: '',
        // 当前页码
        page: 1,
        // 当前每页显示数据数量
        pagenum: 10
      },
      routerList: [],
      total: 0,
      // 控制对话框显示隐藏
      dialogVisible: false,
      form: {},
      dialogTitle: '',
      options: [],
      fileList: [],
      formList: [],
      uploadHeaders: {
        token: window.sessionStorage.getItem('token')
      },
      formRules: {
        title: [{ required: true, message: '请填写视频标题', trigger: 'blur' }],
        target_url: [
          { required: true, message: '请填写跳转链接', trigger: 'blur' }
        ],
        img_src: [
          { required: true, validator: validateLogo, trigger: 'change' }
        ]
      }
    }
  },
  created() {
    this.getList()
  },
  methods: {
    async getList() {
      const { data: res } = await this.$http.post(
        'admin/diagnosisImg/selectList.htm'
      )
      if (res.rep_code !== 10) {
        return this.$message.error('获取列表失败！')
      }
      this.routerList = res.data
    },
    // 监听用户对话框关闭
    dialogClosed() {
      this.$refs.formRef.resetFields()
      this.fileList = []
    },
    // 展示编辑对话框
    async showEditDialog(row) {
      const { data: res } = await this.$http.post(
        'admin/diagnosisImg/selectOne.htm',
        {
          img_id: row.img_id
        }
      )
      if (res.rep_code !== 10) return this.$message.error('查询失败')
      this.form = res.data
      this.dialogVisible = true
    },
    // 编辑
    editList() {
      this.$refs.formRef.validate(async valid => {
        if (!valid) return
        const { data: res } = await this.$http.post(
          'admin/diagnosisImg/saveOneByImgType.htm',
          this.form
        )
        if (res.rep_code !== 10) return this.$message.error(res.rep_msg)
        this.$message.success('编辑成功')
        this.dialogVisible = false
        this.getList()
      })
    },
    handleChange(file, fileList) {
      // 保留最后一个文件
      this.fileList = [fileList[fileList.length - 1]]
    },
    handleSuccess(response, file, fileList) {
      // 处理上传成功后的逻辑
      // this.form.thumb_img = response.data.thumb_img
      this.form.img_src = response.data.original_img
    }
  }
}
</script>
