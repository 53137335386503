<template>
  <div>
    <!-- 面包屑导航区域 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>通知管理</el-breadcrumb-item>
      <el-breadcrumb-item>通知类型列表</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 卡片视图区 -->
    <el-card>
      <!-- 表格 -->
      <!-- stripe设置斑马纹表格border设置竖边框 -->
      <el-table :data="list" border stripe>
        <el-table-column type="index"></el-table-column>
        <el-table-column prop="notice_name" label="通知名称"></el-table-column>
        <el-table-column prop="title" label="标题"></el-table-column>
        <el-table-column prop="notice_type" label="类型"></el-table-column>
        <el-table-column prop="content" label="内容"></el-table-column>
        <el-table-column prop="update_time" label="更新时间">
          <template slot-scope="scope">
            {{ common.formatDate(scope.row.update_time) }}
          </template>
        </el-table-column>
        <el-table-column label="操作" width="174px">
          <template slot-scope="scope">
            <el-tooltip
              effect="dark"
              content="编辑"
              placement="top"
              :enterable="false"
            >
              <el-button
                type="primary"
                icon="el-icon-edit"
                size="mini"
                @click="showEditDialog(scope.row)"
              ></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <!-- 修改用户对话框 -->
    <el-dialog
      title="修改"
      :visible.sync="editDialogVisible"
      width="500px"
      @close="editDialogClosed"
    >
      <el-form
        :model="form"
        :rules="formRules"
        ref="formRef"
        label-width="120px"
      >
        <el-form-item label="标题" prop="title">
          <el-input v-model="form.title"></el-input>
        </el-form-item>
        <el-form-item label="内容" prop="content">
          <el-input type="textarea" v-model="form.content"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="editUser">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [],
      total: 0,
      // 控制对话框显示隐藏
      editDialogVisible: false,
      // 添加用户表单数据
      form: {},
      // 添加表单验证规则
      formRules: {
        title: [{ required: true, message: '请输入标题', trigger: 'blur' }],
        content: [{ required: true, message: '请输入内容', trigger: 'blur' }]
      }
    }
  },
  created() {
    this.getList()
  },
  methods: {
    async getList() {
      const { data: res } = await this.$http.post(
        'admin/notices/selectTypeList.htm'
      )
      if (res.rep_code !== 10) {
        return this.$message.error('获取列表失败！')
      }
      this.list = res.data
    },
    // 监听用户对话框关闭
    editDialogClosed() {
      this.$refs.formRef.resetFields()
    },
    // 展示编辑用户对话框
    async showEditDialog(row) {
      const { data: res } = await this.$http.post(
        'admin/notices/selectTypeOne.htm',
        {
          id: row.id
        }
      )
      if (res.rep_code !== 10) return this.$message.error('查询失败')
      this.form = res.data
      this.editDialogVisible = true
    },
    // 编辑用户
    editUser() {
      this.$refs.formRef.validate(async valid => {
        if (!valid) return
        const { data: res } = await this.$http.post(
          'admin/notices/updateTypeOneTitleAndContent.htm',
          this.form
        )
        if (res.rep_code !== 10) return this.$message.error(res.rep_msg)
        this.$message.success('编辑成功')
        this.editDialogVisible = false
        this.getList()
      })
    }
  }
}
</script>

<style lang="less" scoped></style>
