import Vue from 'vue'
import VueRouter from 'vue-router'
const Login = () => import(/* webpackChunkName: "group-foo" */ '../components/Login.vue')
const Home = () => import(/* webpackChunkName: "group-foo" */ '../components/Home.vue')
const Welcome = () => import(/* webpackChunkName: "group-foo" */ '../components/Welcome.vue')
const PermissionsManagement = () => import(/* webpackChunkName: "group-foo" */ '../components/AdminManagement/PermissionsManagement.vue')
const RoleList = () => import(/* webpackChunkName: "group-foo" */ '../components/AdminManagement/RoleList.vue')
const AdminList = () => import(/* webpackChunkName: "group-foo" */ '../components/AdminManagement/AdminList.vue')
const OnlineRiskAssessment = () => import(/* webpackChunkName: "group-foo" */ '../components/BusinessSecretAssessment/OnlineRiskAssessment.vue')
const EnterpriseDiagnosisAppointment = () => import(/* webpackChunkName: "group-foo" */ '../components/BusinessSecretAssessment/EnterpriseDiagnosisAppointment.vue')
const LatestNews = () => import(/* webpackChunkName: "group-foo" */ '../components/LatestNews/LatestNews.vue')
const OnlineCourses = () => import(/* webpackChunkName: "group-foo" */ '../components/OnlineCourses.vue')
const VideoList = () => import(/* webpackChunkName: "group-foo" */ '../components/FunScience/VideoList.vue')
const CategoryList = () => import(/* webpackChunkName: "group-foo" */ '../components/FunScience/CategoryList.vue')
const CategoryList2 = () => import(/* webpackChunkName: "group-foo" */ '../components/WorkGuideline/CategoryList.vue')
const CategoryList3 = () => import(/* webpackChunkName: "group-foo" */ '../components/LatestNews/CategoryList.vue')
const CategoryList4 = () => import(/* webpackChunkName: "group-foo" */ '../components/TypicalCase/CategoryList.vue')
const LegalGuidelines = () => import(/* webpackChunkName: "group-foo" */ '../components/WorkGuideline/LegalGuidelines.vue')
const ContactUs = () => import(/* webpackChunkName: "group-foo" */ '../components/ContactUs.vue')
const CaseList = () => import(/* webpackChunkName: "group-foo" */ '../components/TypicalCase/CaseList.vue')
const UserList = () => import(/* webpackChunkName: "group-foo" */ '../components/UserList.vue')
const QuickRightsProtection = () => import(/* webpackChunkName: "group-foo" */ '../components/QuickRightsProtection.vue')
const AdsimgList = () => import(/* webpackChunkName: "group-foo" */ '../components/Adsimg/AdsimgList.vue')
const DiagnosisImg = () => import(/* webpackChunkName: "group-foo" */ '../components/BusinessSecretAssessment/DiagnosisImg.vue')
const NoticeTypeList = () => import(/* webpackChunkName: "group-foo" */ '../components/NoticeTypeList.vue')
const UserDiagnosisList = () => import(/* webpackChunkName: "group-foo" */ '../components/BusinessSecretAssessment/UserDiagnosisList.vue')

Vue.use(VueRouter)
// redirect  重新定向
// component 渲染组件
const routes = [
  { path: '/', redirect: '/login' },
  { path: '/login', component: Login },
  {
    path: '/home',
    component: Home,
    redirect: '/welcome',
    children: [
      { path: '/welcome', component: Welcome },
      { path: '/PermissionsManagement', component: PermissionsManagement },
      { path: '/AdminList', component: AdminList },
      { path: '/RoleList', component: RoleList },
      { path: '/OnlineRiskAssessment', component: OnlineRiskAssessment },
      { path: '/EnterpriseDiagnosisAppointment', component: EnterpriseDiagnosisAppointment },
      { path: '/LatestNews', component: LatestNews },
      { path: '/OnlineCourses', component: OnlineCourses },
      { path: '/VideoList', component: VideoList },
      { path: '/FunScience/CategoryList', component: CategoryList },
      { path: '/WorkGuideline/CategoryList', component: CategoryList2 },
      { path: '/LatestNews/CategoryList', component: CategoryList3 },
      { path: '/TypicalCase/CategoryList', component: CategoryList4 },
      { path: '/LegalGuidelines', component: LegalGuidelines },
      { path: '/ContactUs', component: ContactUs },
      { path: '/CaseList', component: CaseList },
      { path: '/QuickRightsProtection', component: QuickRightsProtection },
      { path: '/UserList', component: UserList },
      { path: '/AdsimgList', component: AdsimgList },
      { path: '/DiagnosisImg', component: DiagnosisImg },
      { path: '/NoticeTypeList', component: NoticeTypeList },
      { path: '/UserDiagnosisList', component: UserDiagnosisList }
    ]
  }
]

const router = new VueRouter({
  routes
})
// 挂载路由导航守卫
router.beforeEach(async (to, from, next) => {
  // to 将要访问路径
  // from 代表从哪个路径来
  // next 是一个函数，表示放行
  // next() 放行  next('/login') 强行跳转

  if (to.path === '/login') return next()
  // 获取token
  const token = window.sessionStorage.getItem('token')
  if (!token) next('/login')
  const { data: { rep_code: repCode } } = await Vue.prototype.$http.post('admin/login/getTokenStatus.htm')
  if (repCode !== 10) {
    next('/login')
    window.sessionStorage.clear()
    return
  }
  next()
})

export default router
