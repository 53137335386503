import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: { // 状态
    publicUrl: 'https://wh.soonec.cn/'
  },
  getters: { // 获取器
  },
  mutations: { // 突变
  },
  actions: {
  },
  modules: {

  }
})
export default store
