<template>
  <div>
    <!-- 面包屑导航区域 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>商业秘密评估</el-breadcrumb-item>
      <el-breadcrumb-item>用户诊断报告列表</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 卡片视图区 -->
    <el-card>
      <!-- 搜索与添加区域 -->
      <!-- :gutter表示间距:span表示占据比例clear在clearable点击时触发slot="append"输入框带后置元素 -->
      <!-- 表格 -->
      <!-- stripe设置斑马纹表格border设置竖边框 -->
      <el-table :data="list" border stripe>
        <el-table-column type="index"></el-table-column>
        <el-table-column prop="username" label="用户名"> </el-table-column>
        <el-table-column prop="enterprise_name" label="认证名称">
        </el-table-column>
        <el-table-column prop="sum_score" label="综合得分"></el-table-column>
        <el-table-column prop="create_time" label="创建时间">
          <template slot-scope="scope">
            {{ common.formatDate(scope.row.create_time) }}
          </template>
        </el-table-column>
        <el-table-column label="操作" width="120px">
          <template slot-scope="scope">
            <el-tooltip
              effect="dark"
              content="查看详情"
              placement="top"
              :enterable="false"
            >
              <el-button
                type="primary"
                icon="el-icon-s-fold"
                size="mini"
                @click="showEditDialog(scope.row)"
              ></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页区域 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.page"
        :page-sizes="[1, 2, 5, 10]"
        :page-size="queryInfo.pagenum"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
    <!-- 对话框 -->
    <el-dialog
      title="诊断结果详情"
      :visible.sync="dialogVisible"
      width="500px"
      @close="dialogClosed"
    >
      <img class="cover" :src="$store.state.publicUrl + imgUrls.cover" />
      <div class="one-box">
        <div class="title">
          <em>{{ form.enterprise_name }}</em
          >公司
          <p>商业秘密保护风险诊断建议书</p>
        </div>
        <div class="content">
          {{
            form.enterprise_name
          }}公司：感谢贵司参与武侯区商业秘密保护重点企业风险诊断服务，以下是成都市武侯区商业秘密服务工作站（以下简称“服务站”）根据贵司情况作出的商业秘密保护风险诊断。
        </div>
      </div>
      <div class="result-box">
        <div class="title">一、企业商业秘密保护的总体概况</div>
        <div class="container">
          <div id="main" style="width: 100%;height: 280px;"></div>
        </div>
      </div>
      <div class="result-box box2">
        <div class="title">二、企业商业秘密现状与建议</div>
        <div
          class="box-content"
          v-for="(i, index2) in form.detail_json"
          :key="'a' + index2"
        >
          <div class="option">
            <div class="option-title">
              <i class="icon"></i>
              <em>{{ i.dimension_name }}</em>
              <div>
                评分：<em class="scores">{{ i.sum_score }}</em>
              </div>
            </div>
            <div class="option-content">
              <em class="now">企业现状：</em>
              <div
                v-for="(item, index) in i.present_situation_json"
                :key="'b' + index"
              >
                {{ item.content }}
              </div>
              <em class="suggest">保护建议：</em>
              <div
                v-for="(item, index) in i.suggestion_json"
                :key="'c' + index"
              >
                {{ item.content }}
              </div>
            </div>
          </div>
        </div>
        <div class="box-content" style="padding-bottom: 15px;font-weight: 600;">
          驻区企业若遇到商业秘密泄露事件，请联系成都市武侯区市场监管局进一步提供帮助，企业咨询、维权帮助联系电话：<em
            style="color: #8f2c1d;"
            >028-85559644</em
          >
        </div>
      </div>
      <div class="result-box">
        <p class="title">三、免责声明</p>
        <div style="padding: 0 15px 15px 15px;font-size: 14px;">
          <div class="option" style="margin-bottom: 15px;">
            <div class="option-title">
              <i class="icon"></i>
              1.贵司需确保诊断过程中提供的材料真实、合法，并对材料的真实性、合法性负责。此次分析仅限于贵司提供的材料，不涉及贵司经营现状或其他信息，分析结果仅供参考，不作为正式法律意见或最终结论。
            </div>
          </div>
          <div class="option" style="margin-bottom: 15px;">
            <div class="option-title">
              <i class="icon"></i>
              2.服务站依据现行法律、法规、规章及专业经验进行分析，具体建议旨在提供商业秘密保护决策参考，使用时应保持完整性和严肃性。若贵司依据本建议自行决策并产生损害，服务站不承担法律责任。
            </div>
          </div>
          <div class="option" style="margin-bottom: 15px;">
            <div class="option-title">
              <i class="icon"></i>
              3.我们诚挚地建议贵司关注以下公众号，以便深入了解商业秘密保护的更多相关信息：扫描“商业秘密保护站”二维码并关注，可以获取更多关于商业秘密保护的专业知识、实践经验。扫描“武侯市场监督”二维码并关注，及时掌握商业秘密保护企业服务、科普宣传动态。
            </div>
          </div>
        </div>
      </div>
      <div class="contact">
        <img src="" />
        武侯区市场监管：028-85559644
      </div>
      <div
        style="display: flex;font-size: 12px;color: #a39d9d;justify-content: space-around;text-align: center;"
      >
        <div>
          <img
            :src="$store.state.publicUrl + imgUrls.ewm1"
            alt=""
            style="width:96px;height:96px"
          />
          <p>商业秘密保护工作站</p>
        </div>
        <div>
          <img
            :src="$store.state.publicUrl + imgUrls.ewm2"
            alt=""
            style="width:96px;height:96px"
          />
          <p>武侯市场监督</p>
        </div>
      </div>
      <div
        style="text-align: center;color: #e0d1ce;font-size: 12px;padding:15px;border-bottom: 1px dashed #e0d1ce;"
      >
        成都市武侯区商业秘密保护服务工作站
        <P>(成都市武侯区市场监督管理局)</P>
      </div>
      <div style="padding:15px;font-size:14px;font-weight:600;">
        <div style="margin-bottom: 15px;">
          诊断企业：{{ form.enterprise_name }}
        </div>
        <div>诊断日期：{{ common.formatDate(form.create_time) }}</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    const validateLogo = (rule, value, callback) => {
      if (!this.form.img_src) {
        callback(new Error('请上传图片'))
      } else {
        callback()
      }
    }
    return {
      // 获取用户列表的参数对象
      queryInfo: {
        // 当前页码
        page: 1,
        // 当前每页显示数据数量
        pagenum: 10
      },
      valueArr: [],
      nameArr: [],
      list: [],
      total: 0,
      // 控制对话框显示隐藏
      dialogVisible: false,
      form: {},
      options: [],
      formList: [],
      uploadHeaders: {
        token: window.sessionStorage.getItem('token')
      },
      formRules: {
        title: [{ required: true, message: '请填写视频标题', trigger: 'blur' }],
        target_url: [
          { required: true, message: '请填写跳转链接', trigger: 'blur' }
        ],
        img_src: [
          { required: true, validator: validateLogo, trigger: 'change' }
        ]
      },
      imgUrls: {}
    }
  },
  created() {
    this.getList()
  },
  methods: {
    async getList() {
      const { data: res } = await this.$http.post(
        'admin/diagnosis/selectList.htm',
        this.queryInfo
      )
      if (res.rep_code !== 10) {
        return this.$message.error('获取列表失败！')
      }
      this.list = res.data.list
    },
    // 监听用户对话框关闭
    dialogClosed() {},
    // 展示编辑对话框
    async showEditDialog(row) {
      const { data: res2 } = await this.$http.post(
        'admin/diagnosisImg/selectImgAll.htm'
      )
      this.imgUrls.cover = res2.data.cover.img_src
      this.imgUrls.ewm2 = res2.data.ewm2.img_src
      this.imgUrls.ewm1 = res2.data.ewm1.img_src
      const { data: res } = await this.$http.post(
        'admin/diagnosis/getDetail.htm',
        {
          diagnosis_id: row.diagnosis_id
        }
      )
      if (res.rep_code !== 10) return this.$message.error('查询失败')
      this.form = res.data
      this.nameArr = []
      this.valueArr = []
      for (let i = 0; i < res.data.detail_json.length; i++) {
        this.nameArr.push({
          text: res.data.detail_json[i].dimension_name,
          max: 10
        })
        this.valueArr.push(res.data.detail_json[i].sum_score)
      }
      this.dialogVisible = true
      this.$nextTick(() => {
        this.initChart()
      })
    },
    // 监听每页显示数量改变
    handleSizeChange(newSize) {
      this.queryInfo.pagenum = newSize
      this.getDimensionList()
    },
    // 监听页码改变
    handleCurrentChange(newPage) {
      this.queryInfo.page = newPage
      this.getDimensionList()
    },
    initChart() {
      var myChart = this.$echarts.init(document.getElementById('main'))
      // var myChart = this.$echarts.init(this.$refs.main)
      let i = -1
      myChart.setOption({
        color: '#8f2c1d',
        backgroundColor: '#f5eceb',
        radar: [
          {
            radius: '70%',
            shape: 'circle',
            center: ['50%', '50%'],
            splitNumber: 4,
            indicator: this.nameArr,
            axisLine: {
              lineStyle: {
                color: '#e0d1c1'
              }
            },
            splitLine: {
              lineStyle: {
                color: '#e0d1c1',
                type: 'dashed'
              }
            },
            axisName: {
              rich: {
                a: {
                  padding: [24, 0, 0, 0],
                  fontSize: '12',
                  lineHeight: '14',
                  color: '#292727',
                  fontWeight: 'bold'
                },
                b: {
                  padding: [24, 0, 0, 0],
                  fontSize: '14',
                  lineHeight: '22',
                  color: '#8f2c1d',
                  fontWeight: 'bold',
                  align: 'center'
                }
              },
              formatter: (a, b) => {
                i++
                return `{a|${a}}\n{b|${this.valueArr[i]}}`
              }
            }
          },
          {
            radius: '70%',
            indicator: [
              {
                max: 10
              },
              {
                max: 10
              },
              {
                max: 10
              },
              {
                max: 10
              },
              {
                max: 10
              },
              {
                max: 10
              }
            ],
            splitNumber: 1,
            shape: 'circle',
            center: ['50%', '50%'],
            axisLine: {
              show: false
            },
            splitLine: {
              lineStyle: {
                type: 'solid',
                width: 4,
                color: '#e0d1ce'
              }
            }
          }
        ],
        series: [
          {
            name: '预算 vs 开销',
            type: 'radar',
            symbolSize: '5',
            data: [
              {
                value: this.valueArr,
                name: '评分',
                symbol: 'emptyCircle',
                areaStyle: {
                  color: '#f5eceb'
                }
              }
            ]
          }
        ]
      })
    }
  }
}
</script>
<style lang="less" scoped>
.content-box2 {
  border: 1px solid #e0d1ce;
  border-radius: 0px 0px 8px 8px;
  margin-top: 30px;
  background-color: #fff;
}
.content-bg {
  position: absolute;
  left: 0;
  height: 60px;
  padding: 10px;
  box-sizing: border-box;
  z-index: -1;
}
.cover {
  width: 100%;
}
.one-box {
  padding: 15px;
  font-size: 14px;
  color: #3d3b3b;
  line-height: 22px;
}
.one-box .title {
  font-size: 20px;
  line-height: 28px;
  font-weight: 800;
  text-align: center;
}
.one-box .title em {
  color: #8f2c1d;
}
.result-box .title {
  font-size: 17px;
  line-height: 35px;
  text-align: left;
  margin-left: 15px;
}
.result-box .container {
  width: 100%;
  height: 300px;
  background-color: #f5eceb;
  padding: 15px;
  box-sizing: border-box;
}
ec-canvas {
  width: 100%;
  height: 100%;
}
.box-content {
  background-color: #f5eceb;
  padding: 15px 15px 0 15px;
  font-size: 15px;
}
.now {
  color: #8f2c1d;
  line-height: 18px;
  font-weight: 600;
}
.suggest {
  color: #5467a8;
  line-height: 18px;
  font-weight: 600;
  margin-top: 15px;
  display: inline-block;
}
.scores-box .box-right {
  float: right;
  font-size: 12px;
  color: #a39d9d;
  line-height: 20px;
}
.scores-box .scores {
  font-size: 30px;
  color: #8f2c1d;
  margin-right: 4px;
}
.scores-box .fen {
  float: right;
  font-weight: 400;
}
.result-content .more-box {
  text-align: center;
  line-height: 50px;
  color: #ad8984;
  font-weight: 600;
}
.result-content .icon-xiangyou {
  margin-left: 5px;
}
.bottom-box text {
  opacity: 0.48;
  font-size: 10px;
  display: block;
}
.result-box .question {
  padding: 15px;
  font-weight: 600;
  color: #292727;
  font-size: 15px;
}
.result-box .answer {
  background: #f5f5f5;
  border-radius: 4px;
  margin: 5px 15px 15px;
  color: #665f5e;
}
.oval {
  position: absolute;
  bottom: -21px;
  width: 15px;
  height: 35px;
  background: #ffffff;
  border: 1px solid #e0d1ce;
  border-radius: 9px;
}
.box2 .sumup {
  padding-top: 15px;
  font-weight: 600;
  font-size: 15px;
  line-height: 23px;
}
.box2 .option {
  line-height: 22px;
}
.box2 .option .scores {
  color: #8f2c1d;
}
.box2 .option .option-title {
  font-weight: 600;
}
.option .option-title .icon {
  background: #b85e51;
  display: inline-block;
  height: 12px;
  margin-left: -16px;
  margin-right: 9px;
  position: relative;
  width: 7px;
}
.option .option-title .icon:before {
  border-left: 5px solid #b85e51;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  content: '';
  height: 0;
  top: 0;
  position: absolute;
  right: -5px;
  width: 0;
}
.contact {
  color: #8f2c1d;
  font-size: 15px;
  font-weight: 600;
  text-align: center;
  line-height: 40px;
  background: #f5eceb;
}
.contact image {
  width: 30px;
  height: 30px;
  vertical-align: middle;
}
</style>
