<template>
  <div>
    <!-- 面包屑导航区域 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>商业秘密评估</el-breadcrumb-item>
      <el-breadcrumb-item>线上风险诊断</el-breadcrumb-item>
    </el-breadcrumb>
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="维度" name="dimensionality"></el-tab-pane>
      <el-tab-pane label="问卷" name="questionnaire"></el-tab-pane>
      <el-tab-pane label="问题" name="question"></el-tab-pane>
    </el-tabs>
    <!-- 卡片视图区 -->
    <el-card v-if="activeName == `dimensionality`">
      <!-- 搜索与添加区域 -->
      <el-row :gutter="20">
        <el-col :span="4">
          <el-button
            type="primary"
            @click="
              dialogVisible = true
              dialogTitle = '新增'
              dimensionForm = {}
            "
            >新增</el-button
          >
        </el-col>
      </el-row>
      <!-- 表格 -->
      <!-- stripe设置斑马纹表格border设置竖边框 -->
      <el-table :data="dimensionList" border stripe>
        <el-table-column type="index"></el-table-column>
        <el-table-column
          prop="dimension_name"
          label="维度名字"
        ></el-table-column>
        <el-table-column prop="create_time" label="创建时间">
          <template slot-scope="scope">
            {{ common.formatDate(scope.row.create_time) }}
          </template>
        </el-table-column>
        <el-table-column prop="update_time" label="更新时间">
          <template slot-scope="scope">
            {{ common.formatDate(scope.row.update_time) }}
          </template>
        </el-table-column>
        <el-table-column label="操作" width="174px">
          <template slot-scope="scope">
            <el-tooltip
              effect="dark"
              content="编辑"
              placement="top"
              :enterable="false"
            >
              <el-button
                type="primary"
                icon="el-icon-edit"
                size="mini"
                @click="
                  showDimensionDialog(scope.row)
                  dialogTitle = '编辑'
                "
              ></el-button>
            </el-tooltip>
            <el-tooltip
              effect="dark"
              content="删除"
              placement="top"
              :enterable="false"
            >
              <el-button
                type="danger"
                icon="el-icon-delete"
                size="mini"
                @click="removeDimensionById(scope.row.dimension_id)"
              ></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页区域 -->
      <!-- <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.page"
        :page-sizes="[1, 2, 5, 10]"
        :page-size="queryInfo.pagenum"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination> -->
    </el-card>
    <el-card v-if="activeName == `questionnaire`">
      <!-- 搜索与添加区域 -->
      <el-row :gutter="20">
        <el-col :span="4">
          <el-button
            type="primary"
            @click="
              dialogVisible2 = true
              dialogTitle = '新增'
              questionnaireForm = {}
            "
            >新增</el-button
          >
        </el-col>
      </el-row>
      <!-- 表格 -->
      <!-- stripe设置斑马纹表格border设置竖边框 -->
      <el-table :data="questionnaireList" border stripe>
        <el-table-column type="index"></el-table-column>
        <el-table-column prop="surver_title" label="问卷标题"></el-table-column>
        <el-table-column prop="admin_id" label="管理员ID"></el-table-column>
        <el-table-column prop="create_time" label="创建时间">
          <template slot-scope="scope">
            {{ common.formatDate(scope.row.create_time) }}
          </template>
        </el-table-column>
        <el-table-column prop="is_show" label="是否要显示">
          <template slot-scope="scope">
            <el-tooltip
              :content="scope.row.is_show == 1 ? '是' : '否'"
              placement="top"
            >
              <el-switch
                active-value="1"
                inactive-value="0"
                v-model="scope.row.is_show"
                @change="showChanged(scope.row)"
              >
              </el-switch>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="174px">
          <template slot-scope="scope">
            <el-tooltip
              effect="dark"
              content="编辑"
              placement="top"
              :enterable="false"
            >
              <el-button
                type="primary"
                icon="el-icon-edit"
                size="mini"
                @click="
                  showQuestionnaireDialog(scope.row)
                  dialogTitle = '编辑'
                "
              ></el-button>
            </el-tooltip>
            <el-tooltip
              effect="dark"
              content="删除"
              placement="top"
              :enterable="false"
            >
              <el-button
                type="danger"
                icon="el-icon-delete"
                size="mini"
                @click="removeQuestionnaireById(scope.row.survey_id)"
              ></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页区域 -->
      <el-pagination
        @size-change="handleSizeChange2"
        @current-change="handleCurrentChange2"
        :current-page="queryInfo2.page"
        :page-sizes="[1, 2, 5, 10]"
        :page-size="queryInfo2.pagenum"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
    <el-card v-if="activeName == `question`">
      <el-row :gutter="20">
        <el-col :span="4"
          ><el-select
            v-model="queryInfo3.survey_id"
            @change="getQuestionList"
            placeholder="请选择问卷"
          >
            <el-option
              v-for="item in questionnaireList"
              :key="item.survey_id"
              :label="item.surver_title"
              :value="item.survey_id"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="4">
          <el-button
            type="primary"
            @click="
              dialogVisible3 = true
              dialogTitle = '新增'
              questionForm = { survey_id: '1' }
            "
            >新增</el-button
          >
        </el-col>
      </el-row>
      <el-table :data="questionList" border stripe>
        <el-table-column type="index"></el-table-column>
        <el-table-column
          prop="question_title"
          label="问题标题"
        ></el-table-column>
        <el-table-column prop="question_type" label="问题类型"
          ><template slot-scope="scope">
            {{ digitToChineseText(scope.row.question_type) }}
          </template></el-table-column
        >
        <el-table-column prop="survey_id" label="问卷">
          <template slot-scope="scope">
            {{ questionnaireList[scope.row.survey_id - 1].surver_title }}
          </template>
        </el-table-column>
        <el-table-column prop="dimension_id" label="维度">
          <template slot-scope="scope">
            {{
              scope.row.dimension_id == '0'
                ? '无'
                : dimensionList[scope.row.dimension_id - 1].dimension_name
            }}
          </template>
        </el-table-column>
        <el-table-column label="操作" width="174px">
          <template slot-scope="scope">
            <el-tooltip
              effect="dark"
              content="编辑选项"
              placement="top"
              :enterable="false"
              v-if="scope.row.question_type !== '3'"
            >
              <el-button
                type="primary"
                icon="el-icon-setting"
                size="mini"
                @click="showOptionDialog(scope.row)"
              ></el-button>
            </el-tooltip>
            <el-tooltip
              effect="dark"
              content="编辑"
              placement="top"
              :enterable="false"
            >
              <el-button
                type="primary"
                icon="el-icon-edit"
                size="mini"
                @click="
                  showQuestionDialog(scope.row)
                  dialogTitle = '编辑'
                "
              ></el-button>
            </el-tooltip>
            <el-tooltip
              effect="dark"
              content="删除"
              placement="top"
              :enterable="false"
            >
              <el-button
                type="danger"
                icon="el-icon-delete"
                size="mini"
                @click="removeQuestionById(scope.row.question_id)"
              ></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <!-- 对话框 -->
    <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogVisible"
      width="400px"
      @close="dialogClosed"
    >
      <el-form
        :model="dimensionForm"
        ref="formRef"
        label-width="80px"
        :rules="formRules"
      >
        <el-form-item label="维度名称" prop="dimension_name">
          <el-input v-model="dimensionForm.dimension_name"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button
          type="primary"
          @click="addDimension"
          v-if="dialogTitle === '新增'"
          >确 定</el-button
        >
        <el-button type="primary" @click="editDimension" v-else
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogVisible2"
      width="400px"
      @close="dialogClosed"
    >
      <el-form
        :model="questionnaireForm"
        ref="formRef"
        label-width="80px"
        :rules="formRules"
      >
        <el-form-item label="问卷标题" prop="surver_title">
          <el-input v-model="questionnaireForm.surver_title"></el-input>
        </el-form-item>
        <el-form-item label="是否显示" v-if="dialogTitle === '编辑'">
          <el-radio-group v-model="questionnaireForm.is_show">
            <el-radio label="1">是</el-radio>
            <el-radio label="0">否</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible2 = false">取 消</el-button>
        <el-button
          type="primary"
          @click="addQuestionnaire"
          v-if="dialogTitle === '新增'"
          >确 定</el-button
        >
        <el-button type="primary" @click="editQuestionnaire" v-else
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogVisible3"
      width="400px"
      @close="dialogClosed"
    >
      <el-form
        :model="questionForm"
        ref="formRef"
        label-width="80px"
        :rules="formRules"
        size="mini"
      >
        <el-form-item label="问题标题" prop="question_title">
          <el-input
            type="textarea"
            v-model="questionForm.question_title"
          ></el-input>
        </el-form-item>
        <el-form-item label="问题类型" prop="question_type">
          <el-select v-model="questionForm.question_type">
            <el-option label="单选" value="1"></el-option>
            <el-option label="多选" value="2"></el-option>
            <el-option label="填空" value="3"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="问卷" prop="survey_id">
          <el-select v-model="questionForm.survey_id">
            <el-option
              v-for="item in questionnaireList"
              :key="item.survey_id"
              :label="item.surver_title"
              :value="item.survey_id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="维度" prop="dimension_id">
          <el-select v-model="questionForm.dimension_id">
            <el-option value="0" label="无"></el-option>
            <el-option
              v-for="item in dimensionList"
              :key="item.dimension_id"
              :label="item.dimension_name"
              :value="item.dimension_id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="排序" prop="sort">
          <el-input v-model="questionForm.sort"></el-input>
        </el-form-item>
        <el-form-item label="分数类型" prop="score_type">
          <el-select v-model="questionForm.score_type">
            <el-option value="0" label="无"></el-option>
            <el-option value="1" label="全选正确加分"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="分数添加值" prop="score_add">
          <el-input type="number" v-model="questionForm.score_add"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible3 = false">取 消</el-button>
        <el-button
          type="primary"
          @click="addQuestion"
          v-if="dialogTitle === '新增'"
          >确 定</el-button
        >
        <el-button type="primary" @click="editQuestion" v-else>确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog width="500px" title="编辑选项" :visible.sync="dialogVisible4">
      <el-dialog
        width="500px"
        :title="dialogTitle"
        :visible.sync="innerVisible"
        append-to-body
        @close="dialogClosed"
      >
        <el-form
          :model="optionForm"
          ref="formRef"
          label-width="120px"
          :rules="formRules"
          size="mini"
        >
          <el-form-item label="选项内容" prop="content">
            <el-input v-model="optionForm.content"></el-input>
          </el-form-item>
          <el-form-item label="分数" prop="score">
            <el-input type="number" v-model="optionForm.score"></el-input>
          </el-form-item>
          <el-form-item label="现状（勾选）" prop="present_situation">
            <el-input
              type="textarea"
              v-model="optionForm.present_situation"
            ></el-input>
          </el-form-item>
          <el-form-item label="建议（未勾选）" prop="suggestion">
            <el-input
              type="textarea"
              v-model="optionForm.suggestion"
            ></el-input>
          </el-form-item>
          <el-form-item label="选项类型" prop="option_type">
            <el-select v-model="optionForm.option_type">
              <el-option label="正确项" value="1"></el-option>
              <el-option label="错误项" value="2"></el-option>
              <el-option label="全对项" value="3"></el-option>
              <el-option label="全错项" value="4"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="innerVisible = false">取 消</el-button>
          <el-button
            type="primary"
            @click="addOption"
            v-if="dialogTitle === '新增'"
            >确 定</el-button
          >
          <el-button type="primary" @click="editOption" v-else>确 定</el-button>
        </span>
      </el-dialog>
      <el-row :gutter="20">
        <el-col :span="4">
          <el-button
            type="primary"
            @click="
              optionForm.content = ''
              optionForm.score = '0'
              dialogTitle = '新增'
              innerVisible = true
            "
            >新增</el-button
          >
        </el-col>
      </el-row>
      <el-table :data="optionList" border stripe size="mini" max-height="400px">
        <el-table-column type="index"></el-table-column>
        <el-table-column prop="content" label="选项"></el-table-column>
        <el-table-column prop="score" label="分数"></el-table-column>
        <el-table-column label="操作" width="124px">
          <template slot-scope="scope">
            <el-tooltip
              effect="dark"
              content="编辑"
              placement="top"
              :enterable="false"
            >
              <el-button
                type="primary"
                icon="el-icon-edit"
                size="mini"
                @click="
                  optionForm = scope.row
                  dialogTitle = '编辑'
                  innerVisible = true
                "
              ></el-button>
            </el-tooltip>
            <el-tooltip
              effect="dark"
              content="删除"
              placement="top"
              :enterable="false"
            >
              <el-button
                type="danger"
                icon="el-icon-delete"
                size="mini"
                @click="removeOptionById(scope.row.option_id)"
              ></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 获取用户列表的参数对象
      queryInfo: {
        // 当前页码
        page: 1,
        // 当前每页显示数据数量
        pagenum: 10
      },
      queryInfo2: {
        // 当前页码
        page: 1,
        // 当前每页显示数据数量
        pagenum: 10
      },
      queryInfo3: {
        survey_id: '1',
        // 当前页码
        page: 1,
        // 当前每页显示数据数量
        pagenum: 10
      },
      questionnaireList: [],
      questionList: [],
      dimensionList: [],
      optionList: [],
      total: 0,
      // 控制对话框显示隐藏
      dialogVisible: false,
      dialogVisible2: false,
      dialogVisible3: false,
      dialogVisible4: false,
      innerVisible: false,
      optionForm: {},
      questionnaireForm: {},
      questionForm: {},
      dimensionForm: {},
      dialogTitle: '',
      activeName: 'dimensionality',
      dimension: [],
      survey: [],
      formRules: {
        dimension_name: [
          { required: true, message: '请填写维度名称', trigger: 'blur' }
        ],
        surver_title: [
          { required: true, message: '请填写问卷名', trigger: 'blur' }
        ],
        question_type: [
          { required: true, message: '请选择问题类型', trigger: 'change' }
        ],
        question_title: [
          { required: true, message: '请填写问题', trigger: 'blur' }
        ],
        survey_id: [
          { required: true, message: '请选择问卷', trigger: 'change' }
        ],
        content: [{ required: true, message: '请填写选项', trigger: 'blur' }],
        score: [{ required: true, message: '请填写分数', trigger: 'blur' }]
      }
    }
  },
  created() {
    this.getDimensionList()
  },
  methods: {
    handleClick(tab, event) {
      this.activeName = tab.name
      if (this.activeName === 'dimensionality') {
        this.getDimensionList()
      } else if (this.activeName === 'questionnaire') {
        this.getQuestionnaireList()
      } else {
        this.getDimensionList()
        this.getQuestionnaireList()
        this.getQuestionList()
      }
    },
    async getDimensionList() {
      const { data: res } = await this.$http.post(
        'admin/dimensions/selectList.htm',
        this.queryInfo
      )
      if (res.rep_code !== 10) {
        return this.$message.error('获取列表失败！')
      }
      this.dimensionList = res.data
      // this.total = res.data.pageinfo.count
    },
    async getQuestionnaireList() {
      const { data: res } = await this.$http.post(
        'admin/surveys/selectList.htm',
        this.queryInfo2
      )
      if (res.rep_code !== 10) {
        return this.$message.error('获取列表失败！')
      }
      this.questionnaireList = res.data.list
      this.total = res.data.pageinfo.count
    },
    async getQuestionList() {
      const { data: res } = await this.$http.post(
        'admin/questions/selectList.htm',
        this.queryInfo3
      )
      if (res.rep_code !== 10) {
        return this.$message.error('获取列表失败！')
      }
      this.questionList = res.data
      // this.total = res.data.pageinfo.count
    },
    async getOptionList() {
      const { data: res } = await this.$http.post(
        'admin/options/selectList.htm',
        {
          question_id: this.optionForm.question_id
        }
      )
      if (res.rep_code !== 10) return this.$message.error('查询失败')
      this.optionList = res.data
    },
    // 监听每页显示数量改变
    handleSizeChange(newSize) {
      this.queryInfo.pagenum = newSize
      this.getDimensionList()
    },
    handleSizeChange2(newSize) {
      this.queryInfo2.pagenum = newSize
      this.getQuestionnaireList()
    },
    handleSizeChange3(newSize) {
      this.queryInfo3.pagenum = newSize
      this.getQuestionList()
    },
    // 监听页码改变
    handleCurrentChange(newPage) {
      this.queryInfo.page = newPage
      this.getDimensionList()
    },
    handleCurrentChange2(newPage) {
      this.queryInfo2.page = newPage
      this.getQuestionnaireList()
    },
    handleCurrentChange3(newPage) {
      this.queryInfo3.page = newPage
      this.getQuestionList()
    },
    // 监听是否显示
    async showChanged(row) {
      const { data: res } = await this.$http.post(
        'admin/surveys/updateOne.htm',
        row
      )
      if (res.rep_code !== 10) return this.$message.error(res.rep_msg)
      this.$message.success('编辑成功')
    },
    // 监听用户对话框关闭
    dialogClosed() {
      this.$refs.formRef.resetFields()
    },
    // 添加
    addDimension() {
      this.$refs.formRef.validate(async valid => {
        if (!valid) return
        const { data: res } = await this.$http.post(
          'admin/dimensions/insertOne.htm',
          this.dimensionForm
        )
        if (res.rep_code !== 10) return this.$message.error(res.rep_msg)
        this.$message.success('添加成功')
        this.dialogVisible = false
        this.getDatumList()
      })
    },
    addQuestionnaire() {
      this.$refs.formRef.validate(async valid => {
        if (!valid) return
        const { data: res } = await this.$http.post(
          'admin/surveys/insertOne.htm',
          this.questionnaireForm
        )
        if (res.rep_code !== 10) return this.$message.error(res.rep_msg)
        this.$message.success('添加成功')
        this.dialogVisible2 = false
        this.getQuestionnaireList()
      })
    },
    addQuestion() {
      this.$refs.formRef.validate(async valid => {
        if (!valid) return
        const { data: res } = await this.$http.post(
          'admin/questions/insertOne.htm',
          this.questionForm
        )
        if (res.rep_code !== 10) return this.$message.error(res.rep_msg)
        this.$message.success('添加成功')
        this.dialogVisible3 = false
        this.getQuestionList()
      })
    },
    async addOption() {
      this.$refs.formRef.validate(async valid => {
        if (!valid) return
        const { data: res } = await this.$http.post(
          'admin/options/insertOne.htm',
          this.optionForm
        )
        if (res.rep_code !== 10) return this.$message.error(res.rep_msg)
        this.$message.success('添加成功')
        this.getOptionList()
        this.innerVisible = false
      })
    },
    // 展示编辑对话框
    showDimensionDialog(row) {
      this.dimensionForm = row
      this.dialogVisible = true
    },
    showQuestionnaireDialog(row) {
      this.questionnaireForm = row
      this.dialogVisible2 = true
    },
    async showQuestionDialog(row) {
      const { data: res } = await this.$http.post(
        'admin/questions/selectOne.htm',
        {
          question_id: row.question_id
        }
      )
      if (res.rep_code !== 10) return this.$message.error('查询失败')
      this.questionForm = res.data
      this.dialogVisible3 = true
    },
    async showOptionDialog(row) {
      this.optionForm.question_id = row.question_id
      this.getOptionList()
      this.dialogVisible4 = true
    },
    // 编辑
    editDimension() {
      this.$refs.formRef.validate(async valid => {
        if (!valid) return
        const { data: res } = await this.$http.post(
          'admin/dimensions/updateOne.htm',
          this.dimensionForm
        )
        if (res.rep_code !== 10) return this.$message.error(res.rep_msg)
        this.$message.success('编辑成功')
        this.dialogVisible = false
        this.getDimensionList()
      })
    },
    editQuestionnaire() {
      this.$refs.formRef.validate(async valid => {
        if (!valid) return
        const { data: res } = await this.$http.post(
          'admin/surveys/updateOne.htm',
          this.questionnaireForm
        )
        if (res.rep_code !== 10) return this.$message.error(res.rep_msg)
        this.$message.success('编辑成功')
        this.dialogVisible2 = false
        this.getQuestionnaireList()
      })
    },
    editQuestion() {
      this.$refs.formRef.validate(async valid => {
        if (!valid) return
        const { data: res } = await this.$http.post(
          'admin/questions/updateOne.htm',
          this.questionForm
        )
        if (res.rep_code !== 10) return this.$message.error(res.rep_msg)
        this.$message.success('编辑成功')
        this.dialogVisible3 = false
        this.getQuestionList()
      })
    },
    editOption() {
      this.$refs.formRef.validate(async valid => {
        if (!valid) return
        const { data: res } = await this.$http.post(
          'admin/options/updateOne.htm',
          this.optionForm
        )
        if (res.rep_code !== 10) return this.$message.error(res.rep_msg)
        this.$message.success('编辑成功')
        this.innerVisible = false
        this.getOptionList()
      })
    },
    // 删除
    async removeDimensionById(id) {
      const confirmResoult = await this.$confirm(
        '此操作将永久删除该数据, 是否继续?',
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }
      ).catch(err => err)
      if (confirmResoult === 'confirm') {
        const { data: res } = await this.$http.post(
          'admin/dimensions/deleteOne.htm',
          {
            dimension_id: id
          }
        )
        if (res.rep_code !== 10) return this.$message.error(res.rep_msg)
        this.$message.success('删除成功')
        this.getDimensionList()
      }
    },
    async removeQuestionnaireById(id) {
      const confirmResoult = await this.$confirm(
        '此操作将永久删除该数据, 是否继续?',
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }
      ).catch(err => err)
      if (confirmResoult === 'confirm') {
        const { data: res } = await this.$http.post(
          'admin/surveys/deleteOne.htm',
          {
            survey_id: id
          }
        )
        if (res.rep_code !== 10) return this.$message.error(res.rep_msg)
        this.$message.success('删除成功')
        this.getQuestionnaireList()
      }
    },
    async removeQuestionById(id) {
      const confirmResoult = await this.$confirm(
        '此操作将永久删除该数据, 是否继续?',
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }
      ).catch(err => err)
      if (confirmResoult === 'confirm') {
        const { data: res } = await this.$http.post(
          'admin/questions/deleteOne.htm',
          {
            question_id: id
          }
        )
        if (res.rep_code !== 10) return this.$message.error(res.rep_msg)
        this.$message.success('删除成功')
        this.getQuestionList()
      }
    },
    async removeOptionById(id) {
      const confirmResoult = await this.$confirm(
        '此操作将永久删除该数据, 是否继续?',
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }
      ).catch(err => err)
      if (confirmResoult === 'confirm') {
        const { data: res } = await this.$http.post(
          'admin/options/deleteOne.htm',
          {
            option_id: id
          }
        )
        if (res.rep_code !== 10) return this.$message.error(res.rep_msg)
        this.$message.success('删除成功')
        this.getOptionList()
      }
    },
    digitToChineseText(status) {
      const statusArr = ['', '单选', '多选', '填空']
      return statusArr[status]
    }
  }
}
</script>
